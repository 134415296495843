import { DemoFormData } from "./DemoForm";
import { WebSdk } from "./WebSdk/WebSdk";
import { millisecondsToMinutesDisplay } from "./formatters";

const DEMO_TIME_LIMIT_MS = 2 * 60 * 1000;

export type Scenario = {
  id: string;
  title: string;
  description: string;
  isEnabled: boolean;
  audio: {
    background: {
      url: string;
      volume: number;
    };
  };
};

export type DemoType = "browser" | "phone";

export const SCENARIOS: Scenario[] = [
  {
    id: "demo-it-impersonation",
    title: "IT Support Impersonation",
    description:
      "Play the role of an employee receiving a call. Mirage AI will impersonate a member of your IT team to discuss alleged unusual activity on your account.",
    isEnabled: true,
    audio: {
      background: {
        url: "https://d3ch9yvdkcvbfx.cloudfront.net/office.mp3",
        volume: 0.3,
      },
    },
  },
  {
    id: "demo-vip-pw-reset",
    title: "VIP Password Reset",
    description:
      "Play the role of someone on an IT Helpdesk. Mirage AI will call you, impersonating an executive who needs their password reset.",
    isEnabled: true,
    audio: {
      background: {
        url: "https://d3ch9yvdkcvbfx.cloudfront.net/heathrow_airport.mp3",
        volume: 0.3,
      },
    },
  },
  {
    id: "PHISH",
    title: "Wire Transfer (Coming soon!)",
    description:
      "Play the role of someone on a Finance team. Mirage AI will call you, impersonating a known vendor trying to change the wire transfer number.",
    isEnabled: false,
    audio: {
      background: {
        url: "",
        volume: 0,
      },
    },
  },
];

let mirageClient: WebSdk | null;
const audio = new Audio();
let demoTimer: number;

async function getDemoCall(data: DemoFormData) {
  const callResponse = await fetch(
    `${process.env.REACT_APP_MIRAGE_API_URL}/marketing-demo/start`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(Object.entries(data)),
    }
  );

  const json = await callResponse.json();
  if (!callResponse.ok) {
    /** @todo handle error */
  }

  return json;
}

async function getDemoToken(data: { callId: string }) {
  const callResponse = await fetch(
    `${process.env.REACT_APP_MIRAGE_API_URL}/call/marketing-demo/token`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(Object.entries(data)),
    }
  );

  if (!callResponse.ok) {
    /** @todo handle error */
  }
  const json = await callResponse.json();
  return json;
}

/**
 * Star demo for browser or phone call
 * @returns `demo` result of starting the demo
 * @returns `error` error message to display
 * @returns `message` success message to display
 */
export async function startDemo(
  demoData: DemoFormData
): Promise<{ error?: unknown; message?: string; demo?: unknown }> {
  let demo;
  let error;
  let message;
  switch (demoData.type) {
    case "browser":
      try {
        /** browser calls do not have a phone number, so ensure one is not submitted */
        delete demoData.phoneNumber;
        message = await startBrowserDemo(demoData);
      } catch (e) {
        error = e;
      }
      break;
    case "phone":
      demo = await startCallDemo(demoData);
      if (demo?.ok) {
        await demo.json(); // Assuming the server responds with JSON
        message =
          "We've received request. Please wait 20 seconds for the call to start.";
      } else {
        error = "Failed to start the demo.";
      }
      break;
    default:
      break;
  }

  return { demo, error, message };
}

export async function startCallDemo(data: DemoFormData) {
  // Existing API call to start conference
  const response = await fetch(
    `${process.env.REACT_APP_MIRAGE_API_URL}/marketing-demo/start`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(
        Object.entries({
          ...data,
          phoneNumber: `+1${data.phoneNumber}`
        }).filter(
          ([key, value]) => typeof value !== "undefined"
        )
      ),
    }
  );

  return response;
}

export async function startBrowserDemo(params: DemoFormData): Promise<string> {
  return new Promise(async (resolve, reject) => {
    try {
      const scenario = SCENARIOS.find(
        (scenario) => scenario.id === params.scenarioId
      );
      if (!scenario) {
        return reject("Invalid demo scenario");
      }
      audio.pause();
      audio.src = scenario.audio.background.url;
      audio.volume = scenario.audio.background.volume;
      const { callId } = await getDemoCall(params);
      const { token } = await getDemoToken({ callId });
      mirageClient = new WebSdk({
        deviceToken: token,
        deviceConfig: {
          getUserMedia: (constraints: MediaStreamConstraints) => {
            if (!navigator.mediaDevices?.getUserMedia) {
              reject("Device does not support microphone access.");
            }
            const request = navigator.mediaDevices?.getUserMedia(constraints);
            request.then(
              (mediaStream: MediaStream) => mediaStream,
              (error) =>
                reject(
                  "Please enable microphone access to run the Mirage demo."
                )
            );
            return request;
          },
        },
      });


      mirageClient.on("call-ready", async () => {
        audio.play();
      });

      mirageClient.on("disconnect", () => {
        endDemo(params.type);
        resolve("");
      });

      mirageClient.on("end", () => {
        endDemo(params.type);
        resolve("");
      });

      mirageClient.on("error", () => {
        endDemo(params.type);
        reject("");
      });

      const clientWSParams = new URLSearchParams();
      clientWSParams.set("join", callId);

      await mirageClient.connect({
        url: `${process.env.REACT_APP_MIRAGE_WS_URL}/marketing-demo/connect?${clientWSParams}`,
      });

      try {
        await mirageClient?.device?.connect();

        demoTimer = window.setTimeout(() => {
          endDemo(params.type);
          resolve(
            `Demo is limited to ${millisecondsToMinutesDisplay(
              DEMO_TIME_LIMIT_MS
            )} minutes.`
          );
        }, DEMO_TIME_LIMIT_MS);
      } catch (error) {
        console.log("error", error);
      }
    } catch (error) {
      console.log("error", error);
      reject(`Sorry, an error occuring while starting the demo: ${error}`);
    }
  });
}

export async function endDemo(type: DemoType) {
  if (type === "browser") {
    audio.pause();
    clearTimeout(demoTimer);
    mirageClient?.disconnect();
    mirageClient?.device?.disconnectAll();
    mirageClient = null;
  }
}
